<template>
  <div>
    <!-- Actions -->
    <header class="fanview-header">
      <vs-button
        text-color="white"
        type="flat"
        @click="goToFanview"
      >
        <i class="fa fa-undo-alt mr-1" />

        Voltar para o início
      </vs-button>
    </header>

    <!-- Main -->
    <main class="main">
      <vs-table
        class="fanview-details w-100 pb-4"
        pagination
        search
        stripe
        :data="filteredData"
        :no-data-text="noDataText"
      >
        <template slot="header">
          <header class="d-flex justify-content-between align-items-center w-100">
            <h1>{{ title }}</h1>
          </header>
        </template>

        <template slot="thead">
          <template v-if="type === '1'">
            <!-- Membership Headers -->
            <vs-th
              v-for="header in membershipHeaders"
              :key="header"
              :sort-key="String(header).toLowerCase()"
            >
              {{ header }}
            </vs-th>
          </template>
          <template v-if="type === '2'">
            <!-- Ticket Headers -->
            <vs-th
              v-for="header in ticketHeaders"
              :key="header.name"
              :sort-key="header.sortKey"
            >
              {{ header.name }}
            </vs-th>
          </template>
        </template>

        <template slot-scope="{ data }">
          <template v-if="type === '1'">
            <!-- Memberships -->
            <vs-tr
              v-for="membership in data"
              :key="membership.membership.membId"
            >
              <template v-if="membership.expandedSlot">
                <!-- External ID -->
                <vs-td
                  class="c-expanded"
                  :colspan="membershipHeaders.length"
                >
                  <h6>Acompanhantes</h6>

                  <vs-list>
                    <vs-list-item
                      v-for="item in membership.expandedData"
                      :key="item"
                      icon="account_circle"
                      :subtitle="item"
                    />
                  </vs-list>
                </vs-td>
              </template>
              <template v-else>
                <!-- External ID -->
                <vs-td>
                  <span>
                    {{ membership.membership.externalId || '-' }}
                  </span>
                </vs-td>

                <!-- Subscription Plan -->
                <vs-td>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">{{ membership.membership.plano || '-' }}</span>

                    <vs-tooltip
                      delay=".4s"
                      text="Mostrar acompanhantes"
                    >
                      <div
                        v-if="membership.companions.length > 0"
                        class="c-vs-btn"
                      >
                        <vs-button
                          color="primary"
                          :icon="
                            expanded.includes(membership.membership.membId) ?
                              'person expand_less' :
                              'person expand_more'
                          "
                          size="small"
                          type="flat"
                          @click="() => handleExpand(
                            membership.membership.membId,
                            'membership.membId',
                            membership.companions,
                          )"
                        />
                      </div>
                    </vs-tooltip>
                  </div>
                </vs-td>

                <!-- Status -->
                <vs-td>
                  <span class="text-capitalize">
                    {{ membership.membership.status || '-' }}
                  </span>
                </vs-td>

                <!-- Billing Date -->
                <vs-td>
                  <span class="text-capitalize">
                    {{ getDate(membership.membership.data) || '-' }}
                  </span>
                </vs-td>

                <!-- Activation -->
                <vs-td>
                  <span>
                    {{ getDate(membership.membership.ativacao) }}
                  </span>
                </vs-td>

                <!-- Expires -->
                <vs-td>
                  <span>
                    {{ getDate(membership.membership.validade) }}
                  </span>
                </vs-td>

                <!-- Cost -->
                <vs-td>
                  <span>
                    {{ getMoney(membership.membership.valor) }}
                  </span>
                </vs-td>

                <!-- Installments -->
                <vs-td>
                  <span>
                    {{ membership.membership.parcelas || '-' }}
                  </span>
                </vs-td>

                <!-- Complement -->
                <vs-td>
                  <span>
                    {{ membership.membership.complemento || '-' }}
                  </span>
                </vs-td>

                <!-- Cancellation -->
                <vs-td>
                  <span>
                    {{ getDate(membership.membership.cancelamento) }}
                  </span>
                </vs-td>
              </template>
            </vs-tr>
          </template>
          <template v-else-if="type === '2'">
            <!-- Tickets -->
            <vs-tr
              v-for="ticket in data"
              :key="ticket.evt_tk_id"
              :data="ticket"
            >
              <!-- Event Date -->
              <vs-td>
                <span>{{ getDate(ticket.eventDate) }}</span>
              </vs-td>

              <!-- Event Name -->
              <vs-td>
                <span>{{ ticket.eventName }}</span>
              </vs-td>

              <!-- Purchase Date -->
              <vs-td>
                <span>{{ getDate(ticket.purchaseDate) }}</span>
              </vs-td>

              <!-- Ticket Type -->
              <vs-td>
                <span>{{ ticket.ticketType }}</span>
              </vs-td>

              <!-- Purchase Cost -->
              <vs-td>
                <span>{{ getMoney(ticket.purchaseAmt) }}</span>
              </vs-td>
            </vs-tr>
          </template>
        </template>
      </vs-table>
    </main>
  </div>
</template>

<script>
/* Services */
import Fan from '@/services/fan';

const fanService = new Fan();

export default {
  name: 'FanviewDetails',
  props: {
    fanId: {
      type: Number,
      required: true,
    },
    fanName: {
      type: String,
      required: true,
    },
    document: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    expanded: [],
    filteredData: [],
    tableData: [],
    noDataText: '',
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    membershipHeaders() {
      return [
        'Código',
        'Plano',
        'Status',
        'Data',
        'Ativacao',
        'Validade',
        'Valor',
        'Parcelas',
        'Complemento',
        'Cancelamento',
      ];
    },
    ticketHeaders() {
      return [
        { name: 'Data', sortKey: 'eventDate' },
        { name: 'Nome do evento', sortKey: 'eventName' },
        { name: 'Data da compra', sortKey: 'purchaseDate' },
        { name: 'Tipo de ingresso', sortKey: 'ticketType' },
        { name: 'Valor', sortKey: 'purchaseAmt' },
      ];
    },
  },
  mounted() {
    this.getFanDetails();
  },
  methods: {
    /* API */
    getFanDetails() {
      this.$store.dispatch('set_isLoadingActive', true);

      const systemType = this.getSystemType(this.type);

      fanService
        .getFanDetails(
          systemType,
          this.type,
          this.ownerId,
          this.fanId,
        )
        .then((resp) => {
          this.tableData = resp;
          this.filteredData = resp;
        })
        .catch((err) => (this.noDataText = err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Filters */
    getDate(timedate) {
      if (!timedate) return '-';
      return timedate.replace(/-/g, '/').split('T')[0];
    },
    getMoney(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      return value ? `R${formatter.format(value)}` : '-'
    },
    /* Helpers */
    getSystemType(type) {
      const typeEnum = {
        1: 'membership',
        2: 'tickets',
        3: 'ecommerce',
        4: 'lojasfisicas',
        5: 'ott',
      };

      return typeEnum[type];
    },
    addExpanded(index, payload) {
      this.filteredData = [
        ...this.filteredData.slice(0, index + 1),
        payload,
        ...this.filteredData.slice(index + 1),
      ];
    },
    removeExpanded() {
      let updated = [...this.filteredData];

      updated = updated.filter((row) => row.expandedSlot === undefined);

      this.filteredData = updated;
    },
    handleExpand(id, idGetter, expandedData) {
      // Check if expanded is true
      if (this.expanded.includes(id)) {
        // If expanded is true, remove expanded row
        this.removeExpanded();

        const updated = [...this.expanded];
        this.expanded = updated.filter((expanded) => expanded !== id);
      } else {
        // If expanded is false, add expanded row
        const idGetter1 = idGetter.split('.')[0];
        const idGetter2 = idGetter.split('.')[1];

        const indexAdd = this.filteredData
          .findIndex((row) => row[idGetter1][idGetter2] === id);

        let payload = { expandedSlot: true, expandedData };

        payload[idGetter1] = {};
        payload[idGetter1][idGetter2] = `${id}--expanded`;

        this.addExpanded(indexAdd, payload);
        this.expanded.push(id);
      }
    },
    /* Router */
    goToFanview() {
      this.$router.push({
        name: 'Fanview',
        params: {
          fanId: this.fanId,
          fanName: this.fanName,
          document: this.document,
          email: this.email,
          phone: this.phone,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.c-vs-btn {
  display: flex;

  .vs-button.small {
    padding: 0 20px !important;
  }

  .includeIconOnly.small {
    height: 25px !important;
    width: 25px !important;
  }
}

.c-expanded {
  background-color: #f6f6f6;
  box-shadow:
    inset 0px -5px 5px -5px rgba(0, 0, 0, 0.25),
    inset 0px 5px 5px -5px rgba(0, 0, 0, 0.25);
}

/* Table */
.fanview-details {
  .vs-table--content {
    overflow-x: hidden;

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

<style scoped lang="scss">
/* Text */
.text-capitalize {
  text-transform: capitalize;
}

.fanview-details {
  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.3rem;

    h1 {
      font-size: 1.25rem;
    }
  }
}
</style>
