import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const mktDomain = process.env.VUE_APP_MKTDOMAIN;

const baseEnv = process.env.VUE_APP_BASEENV;

const baseUrl = `${baseDomain}/${baseEnv}`;
const mktUrl = `${mktDomain}/${baseEnv}`;

export default class Fan {
  getFanData(fanId, owner, sysId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/FanData/${fanId}/${owner}/${sysId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getFanDetails(
    systemType,
    type,
    ownerId,
    fanId,
  ) {
    return new Promise((resolve, reject) => {
      let url = `${baseUrl}/FanData/${systemType}/${type}/${ownerId}/${fanId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteFanData(fanId, owner) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/FanData/${fanId}/${owner}`;

      axios
        .delete(url)
        .then(
          (resp) => {
            if (resp.data.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getPwdResetLink(ownerId, identity) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/FanData/LinkResetFanIdPwd/${ownerId}/${identity}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Fan Number */
  updateFanNumber(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/FanData/updateFanNumber`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(url, payload, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteFanNumber(
    ownerId,
    identity,
    number,
    value,
    token,
  ) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/FanData/deleteFanNumber/${ownerId}/${identity}/${number}/${value}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .delete(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatePreferredNumber(payload, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/FanData/updatePreferredFanNumber`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .post(url, payload, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /* Filter */
  getCSVUrl(ownerId, filterUid, idToken) {
    return `${baseUrl}/filterExport/${ownerId}/${filterUid}?token=${idToken}`;
  }

  getIdsSimpleFilter(ownerId, filter, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/idregister/GetIds/${ownerId}/${filter}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getIdsRegister(projectId, filterUuid, token) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/idregister/getIdsByUuid/${projectId}/${filterUuid}`;

      const headers = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .get(url, headers)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getIdsRegisterCount(projectId, filter) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/idregister/getCountIds/${projectId}/${filter}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  integrate(payload) {
    return new Promise((resolve, reject) => {
      const url = `${mktUrl}/contacts/integrate`;

      axios
        .post(
          url,
          payload,
        )
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
